<template>
  <div id="main-wrapper" style="min-height: 97vh">
    <b-overlay :show="d_showOverlay" opacity="1" fixed no-wrap>
      <div style="width: 100%; height: 100%">
        <h1>this page is not allowed for tutors</h1>
      </div>
    </b-overlay>
    <section>
      <div class="container">
        <section class="page-title" v-if="co_hasProduct">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="breadcrumbs-wrap">
                  <h1 class="breadcrumb-title">Your Cart</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="row" v-if="co_hasProduct">
          <div class="col-lg-8 col-md-12">
            <div v-show="!d_isbilling">
              <div class="table-responsive">
                <table class="add-to-cart">
                  <thead>
                    <tr>
                      <th scope="col">Avatar</th>
                      <th scope="col">Title</th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <cmp-packagerow
                      v-for="p_product in gt_getPackagesInCart"
                      :key="p_product.id"
                      :p_product="p_product"
                    >
                    </cmp-packagerow>
                    <cmp-cartrow
                      v-for="p_product in gt_getSessionsInCart"
                      :key="m_generateProductKey(p_product)"
                      :p_product="p_product"
                    >
                    </cmp-cartrow>
                  </tbody>
                </table>
              </div>

              <!-- Coupon Apply -->
              <!-- <div class="checkout-coupon checkout">
                <div class="ckt-last" style="float: right">
                  <form class="form-inline">
                    <button
                      type="button"
                      class="btn empty-btn"
                      @click="m_clearCart()"
                      :disabled="!co_hasProduct"
                    >
                      Empty cart
                    </button>
                  </form>
                </div>
              </div> -->
            </div>
            <div class="pt-0" v-if="d_isbilling">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="cart-totals checkout light-form mb-4">
                      <h4>Billing info</h4>
                      <div class="row">
                        <cmp-textfield
                          ref="ref_firstname"
                          p_title="First Name*"
                          :p_validations="['required', 'alphabet']"
                        ></cmp-textfield>
                        <cmp-textfield
                          ref="ref_lastname"
                          p_title="Last Name*"
                          :p_validations="['required', 'alphabet']"
                        ></cmp-textfield>

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Phone*</label>
                            <VuePhoneNumberInput
                              v-model="d_phone"
                              @update="m_onPhoneChanged"
                              :fetch-country="true"
                              valid-color="#e6eaf3"
                            />
                            <span
                              v-show="d_beginValidation"
                              class="text-danger"
                              style="
                                margin-top: 13px;
                                min-height: 22px;
                                margin-right: 5px;
                                float: right;
                              "
                              >{{ d_phoneerror }}</span
                            >
                          </div>
                        </div>

                        <cmp-textfield
                          ref="ref_address"
                          p_title="Address*"
                          :p_validations="['required', 'minimum-3']"
                        ></cmp-textfield>

                        <cmp-textfield
                          ref="ref_city"
                          p_title="Town/City*"
                          :p_validations="['required', 'minimum-3']"
                        ></cmp-textfield>

                        <cmp-textfield
                          ref="ref_state"
                          p_title="State*"
                          :p_validations="['required', 'minimum-3']"
                        ></cmp-textfield>

                        <div class="col-lg-6 col-md-6">
                          <cmp-country
                            ref="ref_country"
                            @e_change="m_onChangeCountry"
                          />
                        </div>

                        <cmp-textfield
                          ref="ref_zip"
                          p_title="Zip/Postal Code*"
                          :p_validations="['required', 'minimum-3']"
                        ></cmp-textfield>

                        <div class="col-lg-12 col-md-12">
                          <div class="form-group">
                            <button
                              type="button"
                              class="btn btn-light"
                              @click="m_backtoCart"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12">
            <!-- Total Cart -->
            <div class="cart-totals checkout">
              <h4>Billing Summary</h4>
              <div class="cart-wrap">
                <ul class="cart-list">
                  <li>
                    Base price<strong>{{ m_basicPrice() }} AED</strong>
                  </li>
                  <li v-if="hasDiscount">
                    Discount({{ this.discount }}%)<strong
                      >- {{ this.co_discountPrice }} AED</strong
                    >
                  </li>
                  <li>
                    VAT (5%)<strong>{{ m_VAT() }} AED</strong>
                  </li>
                </ul>
                <div class="flex-cart">
                  <div class="flex-cart-1">Total Cost</div>
                  <div class="flex-cart-2">{{ m_totalPrice() }} AED</div>
                </div>
                <div>
                  <b-container fluid>
                    <b-row>
                      <b-col
                        style="display: inline-flex"
                        cols="auto"
                        class="p-0"
                      >
                        <b-form-input
                          style="height: 50px"
                          type="text"
                          v-model="promocode"
                          placeholder="Promocode"
                        ></b-form-input>
                      </b-col>
                      <b-col
                        style="display: inline-flex; justify-content: flex-end"
                        class="p-0"
                      >
                        <b-btn
                          :class="[
                            'btn',
                            'btn-success',
                            buttonLoading ? 'btn-small' : '',
                          ]"
                          @click="m_applyPromocode()"
                          :disabled="buttonLoading"
                        >
                          <b-spinner small v-if="buttonLoading"></b-spinner>
                          <span v-else>Apply</span>
                        </b-btn>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
                <button
                  v-if="false"
                  @click="m_onProceed"
                  :disabled="!co_hasProduct"
                  type="button"
                  class="btn checkout-btn"
                >
                  <span v-if="!d_isbilling"> Proceed To Billing Info </span>
                  <span v-else> Proceed To Checkout </span>
                </button>
                <div
                  v-if="applePay && isSignedin"
                  class="apple-pay-button apple-pay-button-white-with-line"
                  style="margin-top: 20px"
                  @click="m_onApplePayButtonClicked()"
                ></div>
                <div
                  class="text-center"
                  v-if="isSignedin"
                  style="margin-top: 10px"
                >
                  <div class="text-center" style="margin-top: 10px">
                    <b-link
                      @click="m_proceedToBankTransfer"
                      class="banktransfer"
                      style="color: #0000ee"
                      >Pay with bank transfer
                      {{
                        hasDiscount ? "(" + this.discount + "% discount)" : ""
                      }}</b-link
                    >
                  </div>
                </div>
                <div v-else>
                  <p>Please sign in to pay</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else style="padding-top: 20vh">
          <div class="col-12 text-center">
            <h2>Your cart is empty</h2>
          </div>
          <div class="col-12 text-center" style="margin-top: 1%">
            <button
              @click="m_backToSubjectList"
              class="btn btn-light"
              style="font-weight: 700; font-size: 20px"
            >
              Add Subjects
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import c_cartRow from "../cmp-components/cmp-cartrow.vue";
import c_packageRow from "../cmp-components/cmp-packagerow";
import { checkout } from "@/lib-core/lib-firebase";
import c_country from "../cmp-components/cmp-country.vue";
import c_textfield from "../cmp-components/cmp-textfield.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { ios, android } from "platform-detect";
import { Plugins } from "@capacitor/core";
import { fb_settingsRef, applyCode } from "@/lib-core/lib-firebase";
const { Browser, PrivacyScreen } = Plugins;
import { ApplePayPlugin } from "capacitor-apple-pay";
const ApplePay = Plugins.ApplePay;

export default {
  name: "cmp-checkout",
  components: {
    "cmp-cartrow": c_cartRow,
    "cmp-packagerow": c_packageRow,
    "cmp-country": c_country,
    "cmp-textfield": c_textfield,
    VuePhoneNumberInput,
  },
  data() {
    return {
      d_isbilling: false,
      d_country: "",
      d_phone: null,
      d_phonetoSend: null,
      d_phoneerror: null,
      d_beginValidation: null,
      d_showOverlay: false,
      applePay: true,
      discount: 0,
      hasDiscount: false,
      buttonLoading: false,
      promocode: "",
    };
  },

  computed: {
    ...mapGetters("md_auth", ["gt_isAuthenticated", "gt_isVerified"]),
    ...mapGetters("md_fire", ["gt_user"]),
    ...mapGetters("md_cart", [
      "gt_getProductsInCart",
      "gt_getSessionsInCart",
      "gt_getPackagesInCart",
    ]),
    co_discountPrice() {
      return (this.m_basicPrice() * this.discount) / 100;
    },
    co_hasProduct: function () {
      return this.gt_getProductsInCart.length > 0;
    },
    isSignedin: function () {
      return this.gt_user && this.gt_user.account;
    },
    userEmail: function () {
      if (this.gt_user && this.gt_user.account)
        return this.gt_user.account.email;
      return "";
    },
  },
  async mounted() {
    const { isPayment } = await ApplePay.canMakePayments();
    let hideApplePayButton = await fb_settingsRef.get();
    this.applePay = isPayment && !hideApplePayButton.data().hideApplePayButton;

    const l_fromCheckout = await this.$localforage.getItem("fs_fromCheckout");
    let l_delay = 1000;
    if (l_fromCheckout) {
      await this.$localforage.setItem("fs_fromCheckout", false);
      l_delay = 5000;
    }
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, l_delay);
    this.$root.$on("m_backtocart", () => {
      this.m_backtoCart();
    });
  },

  methods: {
    async m_applyPromocode() {
      let promocodePromise = await applyCode({ promocode: this.promocode });
      let hasDiscount = promocodePromise.data.promoExists;
      this.hasDiscount = hasDiscount;
      if (hasDiscount) {
        this.discount = promocodePromise.data.promocodeDiscount;
      } else {
        this.m_showAlertDialog(
          "Invalid promocode",
          "Please enter a valid promocode or leave it empty"
        );
      }
    },
    m_showAlertDialog(p_title, p_msg) {
      let l_payload = {
        title: p_title,
        message: p_msg,
        button: {
          yes: "Ok",
        },
      };

      this.$confirm(l_payload);
    },
    async m_onApplePayButtonClicked() {
      const paymentRequest = {
        // Requiered
        merchantIdentifier: "merchant.com.wedesigndxb.tutors971",
        paymentSummaryItems: [
          {
            label: "971Tutors",
            amount:
              this.userEmail == "bui39973@zwoho.com"
                ? 0.01
                : this.m_totalPrice(),
            // type: 'pending' // or default: final
          },
          // ...
        ],

        // Outher
        countryCode: "AE",
        currencyCode: "AED",

        supportedNetworks: [
          "mastercard",
          "visa",
          "amex",
          "quicPay",
          "chinaUnionPay",
          "discover",
          "interac",
          "privateLabel",
        ],

        merchantCapabilities: [
          "capability3DS",
          "capabilityCredit",
          "capabilityDebit",
          "capabilityEMV",
        ],

        requiredShippingContactFields: [],

        requiredBillingContactFields: [
          "emailAddress",
          "name",
          "phoneNumber",
          "phoneticName",
          "postalAddress",
        ],

        // supportedCountries: [ ... ],
        // billingContact: PaymentContact,
        // shippingContact: PaymentContact,
      };

      try {
        // See: https://developer.apple.com/documentation/passkit/pkpaymenttoken
        const { token } = await ApplePay.makePaymentRequest(paymentRequest);

        try {
          // INFO: Check and completion of the payment by your processing center
          // await MyPaymentProvider.authorize(token.paymentData);

          ApplePay.completeLastTransaction({ status: "success" });
        } catch {
          ApplePay.completeLastTransaction({ status: "error" });
        }
      } catch (e) {
        if (e.message === "canceled") {
          //closed
        }
      }
    },
    m_validateAppleSession() {
      if (!window.ApplePaySession) {
        console.error("This device does not support Apple Pay");
      }

      if (!window.ApplePaySession.canMakePayments()) {
        console.error(
          "This device is not capable of making Apple Pay payments"
        );
      }

      this.applePay = true;
    },
    m_proceedToBankTransfer() {
      if (!this.m_checkEmailVerified()) return;
      if (this.hasDiscount) {
        this.$router.push({
          name: "banktransfer",
          params: { promo: this.promocode },
        });
      } else this.$router.push({ name: "banktransfer" });
    },
    m_generateProductKey(p_product) {
      return p_product.key + p_product.tutor.email;
    },
    m_onPhoneChanged(p_valobj) {
      if (!p_valobj || !p_valobj.isValid) {
        this.d_phoneerror = "Invalid";
        return;
      }
      this.d_phoneerror = null;
      this.d_phonetoSend = p_valobj.e164.substring(1);
    },

    m_createBillingInfo() {
      let l_payload = {
        firstName: this.$refs.ref_firstname.m_getValue(),
        lastName: this.$refs.ref_lastname.m_getValue(),
        phone: this.d_phonetoSend.trim(),
        billingAddress: {
          name:
            this.$refs.ref_firstname.m_getValue() +
            " " +
            this.$refs.ref_lastname.m_getValue(),
          address1: this.$refs.ref_address.m_getValue(),
          state: this.$refs.ref_state.m_getValue(),
          zip: this.$refs.ref_zip.m_getValue(),
          city: this.$refs.ref_city.m_getValue(),
          country: this.d_country,
        },
      };
      return l_payload;
    },
    async m_onProceed() {
      window.scrollTo(0, 0);

      if (!this.gt_isAuthenticated) {
        await this.m_goToSignInThenCheckout();
        return;
      } else if (!this.m_checkEmailVerified()) return;

      if (this.d_isbilling) {
        if (this.m_validateBilling()) {
          this.m_onCheckoutClicked();
        } else {
          let l_payload = {
            message: "Please fill all the required fields",
            button: {
              yes: "Ok",
            },
          };
          this.$confirm(l_payload);
        }
      } else {
        this.m_toggleBilling();
      }
    },
    m_validateBilling() {
      this.d_beginValidation = true;

      return (
        (this.d_phone.trim() != "") &
        (this.d_phonetoSend.trim() != "") &
        this.$refs.ref_country.m_check() &
        this.$refs.ref_firstname.m_check() &
        this.$refs.ref_lastname.m_check() &
        this.$refs.ref_address.m_check() &
        this.$refs.ref_state.m_check() &
        this.$refs.ref_zip.m_check() &
        this.$refs.ref_city.m_check()
      );
    },
    m_emptyBilling() {
      this.d_phone = null;
      this.d_phonetoSend = null;
      this.$refs.ref_country.m_reset();
      this.d_country = "";
      this.$refs.ref_firstname.m_reset();
      this.$refs.ref_lastname.m_reset();
      this.$refs.ref_address.m_reset();
      this.$refs.ref_state.m_reset();
      this.$refs.ref_zip.m_reset();
      this.$refs.ref_city.m_reset();
      this.d_beginValidation = false;
    },
    m_toggleBilling() {
      this.d_isbilling = !this.d_isbilling;
    },
    m_onChangeCountry(p_value) {
      this.d_country = p_value;
    },
    m_basicPrice() {
      return this.gt_getProductsInCart.reduce(
        (current, next) => current + next.price * next.quantity,
        0
      );
    },
    m_VAT() {
      return +(Math.round(this.m_basicPrice() * ((100 - this.discount) / 100) * 0.05 + "e+2") + "e-2");
    },
    m_totalPrice() {
      return this.m_basicPrice() * ((100 - this.discount) / 100) + this.m_VAT();
    },
    async m_clearCart() {
      await this.$store.commit("md_cart/mt_setCart", []);
      await this.$localforage.setItem("fs_cart", []);
    },
    m_backToSubjectList() {
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({ name: "subjects" });
    },
    m_checkoutProductsPayload() {
      let l_ret = [];
      this.gt_getProductsInCart.forEach((p_element) => {
        if (p_element.type == "p") {
          l_ret.push({
            id: p_element.id,
            type: p_element.type,
            quantity: p_element.quantity,
          });
        } else {
          let l_temp = {
            id: p_element.id,
            type: p_element.type,
            quantity: p_element.quantity,
            subject: p_element.key,
            tutor: p_element.tutor.email,
          };
          if (p_element.tutor.grade || p_element.tutor.curriculums)
            l_temp.subjectInfo = {};

          if (p_element.tutor.grade)
            l_temp.subjectInfo.grade = p_element.tutor.grade;

          if (p_element.tutor.curriculums)
            l_temp.subjectInfo.curriculums = p_element.tutor.curriculums;

          l_ret.push(l_temp);
        }
      });

      return l_ret;
    },
    async m_goToSignInThenCheckout() {
      await this.$localforage.setItem("fs_fromCheckout", true);
      await this.$localforage.setItem("fs_tempRole", "student");
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({ name: "access" });
    },
    async m_onCheckoutClicked() {
      this.$store.commit("md_fire/mt_setLoading", true);
      const l_payload = {
        products: this.m_checkoutProductsPayload(),
        billingInfo: this.m_createBillingInfo(),
        promocode: this.promocode
      };

      try {
        const l_response = await checkout(l_payload);
        if (l_response.data && l_response.data.success) {
          if (!ios && !android)
            window.location = l_response.data.result.redirectUrl;
          else {
            const browserOpen = await Browser.open({
              url: l_response.data.result.redirectUrl,
            });
            this.$store.commit("md_fire/mt_setLoading", false);
          }
        } else {
          this.$store.commit("md_fire/mt_setLoading", false);
        }
      } catch (e) {
        this.$store.commit("md_fire/mt_setLoading", false);
        // console.error(e);
      }
    },
    m_backtoCart() {
      window.scrollTo(0, 0);
      if (this.d_isbilling) {
        this.m_emptyBilling();
        this.m_toggleBilling();
      }
    },
    m_checkEmailVerified() {
      if (this.gt_isAuthenticated && !this.gt_isVerified) {
        let l_payload = {
          title: "Email not verified",
          message: "Please verify your email before checking out",
          button: {
            yes: "Verify",
            no: "Later",
          },
          callback: (confirm) => {
            if (confirm) {
              this.$router.push({ name: "verify" });
            }
          },
        };
        this.$confirm(l_payload);
        return false;
      }
      return true;
    },
    m_denyTutorAccess(p_user) {
      if (p_user && p_user.account && p_user.account.role === "tutor") {
        this.d_showOverlay = true;
        this.$router.push({ name: "home" });
      }
    },
  },
  watch: {
    gt_user(p_user) {
      this.m_denyTutorAccess(p_user);
    },
  },
};
</script> 

<style >
.input-tel__input {
  background: #f4f5f7;
  height: 54px !important;
}
.country-selector__input {
  background: #f4f5f7;
  height: 54px !important;
}
#MazPhoneNumberInput-32_country_selector {
  background: #f4f5f7;
  height: 54px !important;
}
#MazPhoneNumberInput-32_phone_number {
  background: #f4f5f7;
  height: 54px !important;
}

.banktransfer:hover {
  text-decoration: underline;
  color: #83bf6f !important;
}

.apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: check-out; /* Use any supported button type. */
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
  width: 100%;
  height: 40px;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
  width: 100%;
  height: 30px;
}
</style>