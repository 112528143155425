<template>
  <tr>
    <td data-label="AVATAR">
      <cmp-tutoravatar p_name="p5" p_size="4rem"></cmp-tutoravatar>
    </td>
    <td data-label="TITLE">
      Package
      <span class="tb-date"> {{ p_product.nrOfSessions }} Sessions </span>
    </td>
    <td data-label="PRICE">
      <span class="wish-price theme-cl">{{ co_packagePrice }} AED</span>
    </td>
    <td data-label="QUANTITY">
      <input
        type="number"
        class="form-control qty"
        step="1"
        v-model="d_quantity"
        title="Qty"
        size="4"
        min="1"
        placeholder=""
        inputmode="numeric"
        @change="m_changeQuantity(d_quantity)"
        style="display: inline"
      />
    </td>
    <td data-label="ACTION">
      <a href="#" class="btn btn-remove" @click="m_removePackage()">Remove</a>
    </td>
  </tr>
</template>

<script>
import c_tutorAvatar from "./cmp-tutoravatar.vue";
export default {
  name: "cmp-packagerow",
  components: {
    "cmp-tutoravatar": c_tutorAvatar,
  },
  data() {
    return { d_quantity: 1 };
  },
  mounted() {
    this.d_quantity = this.p_product.quantity;
  },
  props: {
    p_product: {
      type: Object,
    },
  },
  computed: {
    co_packagePrice(){
      return this.p_product.price;
    }
  },
  methods: {
    async m_removePackage() {
      this.$store.commit("md_cart/mt_removePackage", {
        id: this.p_product.id,
      });
      await this.$localforage.setItem(
        "fs_cart",
        this.$store.state.md_cart.st_cartProducts
      );
    },
    async m_changeQuantity(p_value) {
      this.$store.commit("md_cart/mt_changePackageQuantity", {
        id: this.p_product.id,
        quantity: p_value,
      });
      await this.$localforage.setItem(
        "fs_cart",
        this.$store.state.md_cart.st_cartProducts
      );
    },
  },
};
</script>

