<template>
  <div class="col-lg-6 col-md-6">
    <div class="form-group">
      <label>{{ p_title }}</label>

      <input
        type="text"
        style="background: #fff"
        class="form-control"
        v-model="d_value"
        @input="m_onchange"
      />
      <span
        class="text-danger"
        style="min-height: 22px; margin-right: 5px; float: right"
        >{{ d_error }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "cmp-textfield",
  props: {
    p_title: {
      type: String,
    },
    p_validations: {
      type: Array,
    },
  },
  data() {
    return {
      d_value: null,
      d_error: null,
    };
  },

  methods: {
    m_getValue() {
      if (!this.d_value) return "";
      return this.d_value.trim();
    },
    m_reset() {
      this.m_resetValue();
      this.m_resetError();
    },
    m_resetValue() {
      this.d_value = null;
    },
    m_resetError() {
      this.d_error = null;
    },
    m_onchange() {
      this.m_check();
    },
    m_check() {
      this.d_error = null;

      if (!this.p_validations || this.p_validations.length == 0) {
        return true;
      }
      for (let l_index = 0; l_index < this.p_validations.length; l_index++) {
        this.m_checkSingleValidation(this.p_validations[l_index]);
        if (this.d_error) return false;
      }
      return true;
    },
    m_checkSingleValidation(p_validation) {
      switch (p_validation) {
        case "required":
          if (this.m_isNotEmpty(this.d_value)) return;
          this.d_error = "Required";
          break;
        case "minimum-3":
          if (this.m_isAboveMinimum(this.d_value, 3)) return;
          this.d_error = "Invalid";
          break;
        case "alphabet":
          if (this.m_isAlphabetOnly(this.d_value)) return;
          this.d_error = "Invalid";
          break;
      }
    },
    m_isNotEmpty(p_field) {
      return this.m_isAboveMinimum(p_field, 1);
    },
    m_isAboveMinimum(p_field, p_minimum) {
      return p_field && p_field.trim().length >= p_minimum;
    },
    m_isAlphabetOnly(p_field) {
      return /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(p_field);
    },
  },
};
</script>

<style>
</style>