<template>
  <tr>
    <td data-label="AVATAR">
      <cmp-tutoravatar
        :p_name="p_product.tutor.displayName"
        :p_avatar="p_product.tutor.avatar"
        p_size="4rem"
      ></cmp-tutoravatar>
    </td>
    <td data-label="TITLE">
      {{ p_product.en }}
      <span class="tb-date">{{ p_product.tutor.displayName }}</span>
    </td>
    <td data-label="PRICE">
      <span class="wish-price theme-cl">{{ gt_sessionPrice }} AED</span>
    </td>
    <td data-label="QUANTITY">
      <input
        type="number"
        class="form-control qty"
        step="1"
        v-model="d_quantity"
        title="Qty"
        size="4"
        min="1"
        placeholder=""
        inputmode="numeric"
        @change="m_changeQuantity(d_quantity)"
        style="display: inline"
      />
    </td>
    <td data-label="ACTION">
      <a href="#" class="btn btn-remove" @click="m_removeProduct()">Remove</a>
    </td>
  </tr>
</template>

<script>
import c_tutorAvatar from "./cmp-tutoravatar";
import { mapGetters } from "vuex";
export default {
  name: "cmp-cartrow",
  components: {
    "cmp-tutoravatar": c_tutorAvatar,
  },
  computed: {
    ...mapGetters("md_fire", ["gt_sessionPrice"]),
  },
  data() {
    return { d_quantity: 1 };
  },
  mounted() {
    this.d_quantity = this.p_product.quantity;
  },
  props: {
    p_product: {
      type: Object,
    },
  },
  methods: {
    async m_removeProduct() {
      this.$store.commit("md_cart/mt_removeProduct", {
        subject: this.p_product.en,
        tutor: this.p_product.tutor.email,
      });
      await this.$localforage.setItem(
        "fs_cart",
        this.$store.state.md_cart.st_cartProducts
      );
    },
    async m_changeQuantity(p_value) {
      let l_payload = { ...this.p_product, quantity: p_value };
      this.$store.commit("md_cart/mt_changeProductQuantity", l_payload);
      await this.$localforage.setItem(
        "fs_cart",
        this.$store.state.md_cart.st_cartProducts
      );
    },
  },
};
</script>

<style>
</style>